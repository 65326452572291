<template lang="html">
    <div class="layout--main" >
      <div class="container mt-12 pb-12">
        
        <div class="row mb-8">
          <router-link to="/Calculadora">
            <button type="button" class="btn regresar w-48 flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 30 22" fill="none">
                <path d="M27.5 22V15.1643C27.5 13.75 26.9722 12.5452 25.9167 11.55C24.8611 10.5548 23.5833 10.0571 22.0833 10.0571H4.75L11.1667 16.1071L9.41667 17.7571L0 8.87857L9.41667 0L11.1667 1.65L4.75 7.7H22.0833C24.25 7.7 26.1111 8.42679 27.6667 9.88036C29.2222 11.3339 30 13.0952 30 15.1643V22H27.5Z" fill="#FF7150"/>
              </svg>
              <span class="ml-4">
                Regresar
              </span>
            </button>
          </router-link>
        </div>

        <div class="row">
          Cotización rapida
        </div>

        <div class="row mt-8 mb-8 p-8 pb-8 bg-calculadora">
          <div class="row">
          Datos de tu cliente
        </div>

        <div class="row mt-24">
          <div class="col-sm-4">
            <label>Nombres</label>
            <div class="flex">
              <input type="text" class="form-control" placeholder="" aria-label="costo ">
            </div>
          </div>
          <div class="col-sm-4">
            <label>Apellido Paterno</label>
            <div class="flex">
              <input type="text" class="form-control" placeholder="" aria-label="costo ">
            </div>
          </div>
          <div class="col-sm-4">
            <label>Apellido Materno</label>
            <input type="text" class="form-control" placeholder="" aria-label="costo ">
          </div>
        </div>

        <div class="row mt-10">
          <div class="col-sm-4">
            <label>Correo</label>
            <div class="flex">
            <input type="text" class="form-control" placeholder="" aria-label="costo ">
            </div>
          </div>
          <div class="col-sm-4">
            <label>Telefono</label>
            <div class="flex">
              <input type="text" class="form-control" placeholder="" aria-label="costo ">
            </div>
          </div>

        </div>
        <div class="row mt-32 mb-8 justify-end">
          <router-link to="/Calculadora-producto" class="flex justify-end">
            <button type="button" class="btn btn-blue w-64">Siguiente</button>
          </router-link>
        </div>
        
      </div>
    </div>
  </div>
  

</template>

<script>
export default {
  name: "Calculadora-datos"
}
</script>

<style lang="scss" scoped>
.bg-calculadora{
  background-color:#F2F2F2;
  border-radius: 20px;
}
.btn-blue {
    color: #fff;
    background-color: #079DEF !important;
    border-color: #079DEF;
    margin-left: 0px !important;
}
.regresar{
  background-color: #fff;
  color: #FF7150;
  border: none;
}
</style>